import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Pricing from '../../../components/pricing';
import PricingText from '../../../components/pricing.text';

import imgSEO from '../../../assets/img/heroes/social_ads.jpg';
import SimilarCard from '../../../components/similar.card';
import similar1 from '../../../assets/img/heroes/thumbnails/social_branding.jpg';
import similar2 from '../../../assets/img/heroes/thumbnails/remarketing.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/newsletter.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/social_ads.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Social Ads | Agence digitale | MDS Digital Agency"
      description="Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      keywords="référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Social Ads</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>
          Montrer vos publicités à des personnes ayant un réel intérêt pour votre marque.
        </h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          <Col md={8} className={pageStyle.flex_v_align}>
            <p>
              Le service social ADS permet de diffuser vos publicités sur le fil d’actualité des
              personnes les plus susceptibles d’être intéressées par votre marque.
            </p>
            <p>
              Que vous vouliez faire connaître votre marque à de nouvelles personnes, les amener sur
              votre site web ou tout simplement accroître vos ventes, c’est la solution parfaite
              pour obtenir des meilleurs résultats.
            </p>
            <p>
              MDS Digital Agency met en place plusieurs variantes de vos campagnes publicitaires
              afin de voir lesquelles obtiendront les meilleurs résultats. Par la suite, seules les
              variantes les plus performantes seront gardées.
            </p>
          </Col>
          <Col md={4}>
            {/* <img src={socialAds} alt="" /> */}
            <Pricing title="Ads">
              <PricingText>Création de 4 à 5 publicités</PricingText>
              <PricingText>Réalisation des textes et visuels</PricingText>
              <PricingText>Création de la publicité sur un réseau social</PricingText>
              <PricingText>Optimisation du budget et de la publicité une fois par mois</PricingText>
              <PricingText>Budget publicitaire à convenir ensemble</PricingText>
              <PricingText>En option: landing page</PricingText>
            </Pricing>
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Quel impact aurait la publicité sur Facebook pour mon activité ?"
        subtitle="Quels résultats pourrais-je obtenir ?"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Social Branding"
            url="/services/reseaux-sociaux/social-branding/"
            img={similar1}
          />
          <SimilarCard
            text="Remarketing"
            url="/services/reseaux-sociaux/remarketing/"
            img={similar2}
          />
          <SimilarCard text="Newsletter" url="/services/newsletter/" img={similar3} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
