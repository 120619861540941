import React from 'react';
import pricingStyle from '../components/pricing.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

class Pricing extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div className={pricingStyle.container}>
        <h1>{this.props.title}</h1>
        <div className={pricingStyle.content}>{this.props.children}</div>
      </div>
    );
  }
}

export default Pricing;
