import React from 'react';
import pricingStyle from '../components/pricing.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

class PricingText extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return <div className={pricingStyle.text}>{this.props.children}</div>;
  }
}

export default PricingText;
